<template>
  <div class="row">
    <form class="form">
      <div class="form__group">
        <label for="couponName" class="form__label">Coupon Name</label>
        <input
          type="text"
          class="form__input"
          placeholder="e.g END.OF.YEAR.DISCOUNT"
          id="couponName"
          v-model="coupon.code"
        />
      </div>

      <div class="form__group">
        <label for="discountPrice" class="form__label"
          >Discount Price (%)</label
        >
        <input
          type="number"
          class="form__input"
          placeholder="e.g 40%"
          id="discountPrice"
          v-model="coupon.percent_off"
        />
      </div>

      <div class="form__group">
        <label for="duration" class="form__label">Duration</label>
        <select
          name="duration"
          id="duration"
          class="form__input"
          v-model="coupon.expires_at"
        >
          <option disabled value="once" selected>Once</option>
          <option disabled value="repeating">Repeating</option>
          <option disabled value="forever">Forever</option>
        </select>
      </div>

      <div class="form__group">
        <ca-button :disable="loading" @click.native="editCoupon">Edit Coupon <span v-if="loading"><i class="fa fa-spinner fa-spin"></i></span> </ca-button>
      </div>
    </form>
  </div>
</template>

<script>
import CaButton from "../../../../components/buttons/CaButton";
import axios from "../../../../../axios";
export default {
  name: "EditPromoCode",
  components: { CaButton },
  data() {
    return {
      loading: false,
      coupon: {
        code: "",
        percent_off: "",
        expires_at: "repeating",
      },
    };
  },
  methods: {
    getCoupon() {
      const loading = this.$vs.loading();
      axios
        .get(
          `${this.$store.state.server.requestUri}/admin/coupons/${this.$route.params.couponId}`
        )
        .then((res) => {
          const data = res.data.data;
          this.coupon.code = data.id;
          this.coupon.percent_off = data.percent_off;
          loading.close();
        })
        .catch((err) => {
          loading.close();
          console.log(err.response);
        });
    },
    editCoupon(){
      const loading = this.$vs.loading();
      this.loading = true;
      axios
        .patch(
          `${this.$store.state.server.requestUri}/admin/coupons/${this.$route.params.couponId}`,
          this.coupon
        )
        .then((res) => {
          loading.close();
          this.loading = false;
          console.log(res);
          this.$toast("Coupon edited successfully", {
            type: "success",
            position: "top-center",
          });
          console.log(res);
        })
        .catch((err) => {
          loading.close();
          this.loading = false;
          console.log(err.response);
        });
    }
  },

  created() {
    this.getCoupon();
  },
};
</script>

<style scoped></style>
